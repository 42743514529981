<template>
  <v-container>
    <h5 class="text-center">Speak More</h5>
    <h2 class="text-center">- 選択 -</h2>

    <template v-if="$vuetify.display.xs">
      <h5 class="text-center mt-4" style="color: grey;">
        以下一覧から発声実践するチャンク文/フレーズを選択してください。
      </h5>
    </template>
    <template v-else>
      <h4 class="text-center mt-4" style="color: grey;">
        以下一覧から発声実践するチャンク文/フレーズを選択してください。
      </h4>
    </template>

    <v-divider class="mt-4"></v-divider>

    <!-- 実践ボタン -->
    <div class="d-flex justify-space-evenly my-2 py-2">
      <v-btn @click="goToPractice" width="45%" class="border" color="primary"
        :disabled="selected_chunks.length === 0">実践（選択）</v-btn>
      <v-btn @click="openRandomDialog" width="45%" :prepend-icon="mdi_random" class="border"
        color="primary">実践（ランダム）</v-btn>
    </div>

    <!-- 検索フィールド -->
    <v-text-field v-model="search_keyword" single-line density="compact" label="クイック検索" outlined hide-details clearable
      :prepend-inner-icon="mdi_magnify"></v-text-field>

    <!-- フォルダごとのチャンク文/フレーズの一覧 -->
    <div class="border-lg rounded-lg pa-4 my-2" v-for="folder in filtered_chunk_sentences" :key="folder.folder_id">
      <div class="d-flex justify-space-between align-center">
        <h3>{{ folder.folder_name }}</h3>
        <v-btn icon @click="toggleFolderVisibility(folder.folder_id)">
          <v-icon>{{ folderVisibility[folder.folder_id] ? mdiChevronUp : mdiChevronDown }}</v-icon>
        </v-btn>
      </div>
      <v-expand-transition>
        <div v-show="folderVisibility[folder.folder_id]">
          <template v-for="(chunk_sentence_row, index) in folder.chunk_sentences">
            <ChunkSentenceSelectCompo :props_chunk_sentence_row="chunk_sentence_row" :index="index + 1"
              :key="chunk_sentence_row.id" v-if="true" @update:selected="updateSelected">
              <template #index>{{ index + 1 }}</template>
            </ChunkSentenceSelectCompo>
          </template>
        </div>
      </v-expand-transition>
    </div>

    <!-- ランダム実践設定ダイアログ -->
    <v-dialog v-model="randomDialog" max-width="500">
      <v-card>
        <v-card-title>
          <span class="headline">ランダム実践設定</span>
        </v-card-title>
        <v-card-text>
          <v-select v-model="selectedFolderId" :items="folderOptions" label="フォルダ選択" item-title="name"
            item-value="id"></v-select>
          <v-text-field v-model="randomCount" type="number" label="選択するチャンク文の数" min="1"
            :max="totalChunkSentenceCount"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="randomDialog = false">キャンセル</v-btn>
          <v-btn color="primary" text @click="startRandomPractice">開始</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-container>
</template>

<script>
import { mdiMagnify, mdiPlusCircle, mdiRandom, mdiChevronDown, mdiChevronUp } from "@mdi/js"; // アイコンのインポート
import { mapActions } from "vuex"; // Vuexのアクションをマッピングするための関数をインポート
import ChunkSentenceSelectCompo from "../../components/ChunkSentenceSelect-Compo.vue"; // コンポーネントのインポート

export default {
  components: {
    ChunkSentenceSelectCompo,
  },
  data() {
    return {
      mdi_magnify: mdiMagnify,
      mdi_plus_circle: mdiPlusCircle,
      mdi_random: mdiRandom,
      mdiChevronDown: mdiChevronDown,
      mdiChevronUp: mdiChevronUp,

      all_chunk_sentence_rows: [], // すべてのチャンク文/フレーズのリスト
      filtered_chunk_sentences: [], // フィルタリングされたチャンクのリスト

      search_keyword: "", // 検索ワード
      selected_chunks: [], // 選択されたチャンクのリスト

      randomDialog: false, // ランダム設定ダイアログの表示状態
      randomCount: 5, // ランダムに選択するチャンクの数

      folderVisibility: {}, // フォルダの開閉状態を管理
      selectedFolderId: null, // 選択されたフォルダのID
      folderOptions: [] // フォルダの選択肢
    };
  },
  computed: {
    totalChunkSentenceCount() {
      return this.filtered_chunk_sentences.reduce((total, folder) => total + folder.chunk_sentences.length, 0);
    }
  },
  methods: {
    ...mapActions(['addChunk', 'removeChunk', 'clearChunks']), // Vuexのアクションをマッピング
    // フォルダの開閉状態を切り替えるメソッド
    toggleFolderVisibility(folderId) {
      if (Object.prototype.hasOwnProperty.call(this.folderVisibility, folderId)) {
        this.folderVisibility[folderId] = !this.folderVisibility[folderId];
      } else {
        this.folderVisibility = { ...this.folderVisibility, [folderId]: true };
      }
    },
    // チャンクをフィルタリングするメソッド
    filterChunks() {
      this.filtered_chunk_sentences = this.all_chunk_sentence_rows.map(folder => {
        return {
          ...folder,
          chunk_sentences: folder.chunk_sentences.filter(chunk_sentence => {
            // 検索ワードが空の場合はすべてのチャンクを表示
            if (!this.search_keyword) return true;
            // 検索ワードがチャンクに含まれる場合は表示
            return (
              chunk_sentence.chunk_sentence.includes(this.search_keyword) ||
              chunk_sentence.translating_sentence.includes(this.search_keyword) ||
              chunk_sentence.situation.includes(this.search_keyword) ||
              chunk_sentence.notes.includes(this.search_keyword)
            );
          })
        };
      });
    },
    // チェックボックスの状態を更新するメソッド
    updateSelected(checked, chunk_sentence_row) {
      if (checked) {
        this.selected_chunks.push(chunk_sentence_row); // チャンク文を選択リストに追加
        this.addChunk(chunk_sentence_row); // チャンク文を追加
      } else {
        // チャンク文を選択リストから削除
        const index_to_delete = this.selected_chunks.findIndex(chunk => chunk.chunk_id === chunk_sentence_row.chunk_id);
        this.selected_chunks.splice(index_to_delete, 1);

        this.removeChunk(chunk_sentence_row); // チャンク文を削除
      }
    },
    // 実践ページに移動するメソッド
    goToPractice() {
      this.$router.push("/speak_more/practice");
    },
    openRandomDialog() {
      this.randomDialog = true;
    },
    startRandomPractice() {
      const folder = this.filtered_chunk_sentences.find(f => f.folder_id === this.selectedFolderId);
      if (folder) {
        const shuffled = folder.chunk_sentences.sort(() => 0.5 - Math.random());
        this.selected_chunks = shuffled.slice(0, this.randomCount);
        // console.log("this.selected_chunks: ", this.selected_chunks )
        this.clearChunks();
        this.selected_chunks.forEach(chunk => this.addChunk(chunk));
        this.randomDialog = false;
        this.goToPractice();
      } else {
        console.error("Selected folder is not found");
      }
    },
    async fetchChunkSentences() {
      try {
        const response = await fetch(`/api/chunk_sentences/list`, {
          method: "GET",
        });

        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }

        const data = await response.json();
        this.all_chunk_sentence_rows = data;
        // console.log("this.all_chunk_sentence_rows: ",this.all_chunk_sentence_rows)
        this.filterChunks();
        // フォルダの初期状態を設定
        data.forEach(folder => {
          this.folderVisibility = { ...this.folderVisibility, [folder.folder_id]: false }; // デフォルトで全てのフォルダを開く
          this.folderOptions.push({ id: folder.folder_id, name: folder.folder_name });
        });
        // console.log("data: ", data)
      } catch (error) {
        console.error("Fetch error:", error);
      }
    }
  },
  async mounted() {
    this.clearChunks();
    await this.fetchChunkSentences();
  },
  watch: {
    // 検索ワードが変更されたときにチャンクをフィルタリング
    search_keyword: function () {
      this.filterChunks();
    },
  },
};
</script>
