<template>
  <v-app :theme="theme">
    <!-- アプリケーションバー (上段バー) -->
    <v-app-bar color="primary">
      <!-- 左アイテム -->
      <template v-slot:prepend>
        <!-- ナビゲーションボタン -->
        <v-app-bar-nav-icon @click.stop="drawer = !drawer">
        </v-app-bar-nav-icon>
      </template>
      <v-app-bar-title>らくらく英会話(仮)</v-app-bar-title>

      <!-- 右アイテム -->
      <template v-slot:append>
        <v-spacer></v-spacer>
        <v-avatar v-if="isLoggedIn" @click="goToProfile" style="cursor: pointer;">
          <!-- <img :src="avatarUrl" alt="Avatar"> -->
          <img src="@/assets/logo.png" alt="avatarImage" class="avatar-image" />
        </v-avatar>
      </template>
    </v-app-bar>

    <!-- メインコンテンツ -->
    <v-main>
      <router-view />
      <!-- router のコンポーネント埋め込み -->
    </v-main>

    <!-- 下段ナビゲーション (フッターは v-main の height で高さ調節) -->
    <!-- app 属性を付けると画面下部に固定できる -->
    <v-footer color="primary" app height="30" class="d-flex justify-center">
      <span class="white--text">&copy; 2024 RakuRakuEikaiwa(kari)</span>
    </v-footer>

    <!-- 左ナビゲーション -->
    <v-navigation-drawer v-model="drawer" temporary>
      <v-list nav>
        <!-- セッションがない場合のナビゲーション -->
        <template v-if="!isLoggedIn">
          <v-list-item title="アカウント登録" value="register" to="/account/register"></v-list-item>
          <v-list-item title="ログイン" value="login" to="/account/login"></v-list-item>
        </template>

        <!-- セッションがある場合のナビゲーション -->
        <template v-else>
          <!-- ホーム -->
          <v-list-item title="ホーム" value="home" to="/" :prepend-icon="mdiHome"></v-list-item>

          <!-- 機能 -->
          <v-list-group value="Features">
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props" title="機能" :prepend-icon="mdiTranslateVariant"></v-list-item>
            </template>
            <v-list-item title="Chunk Hub" value="folders" to="/folders/list"
              :prepend-icon="mdiBookshelf"></v-list-item>
            <v-list-item title="Speak More" value="speak_more" to="/speak_more/select"
              :prepend-icon="mdiAccountVoice"></v-list-item>
          </v-list-group>

          <!-- 学習分析 -->
          <v-list-group value="Analytics">
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props" title="分析" :prepend-icon="mdiGoogleAnalytics"></v-list-item>
            </template>
            <v-list-item title="チャンクネットワーク" value="chunk_network" to="/pyvis"
              :prepend-icon="mdiHubOutline"></v-list-item>
            <v-list-item title="学習統計" value="Statistics" to="/statistics" :prepend-icon="mdiSchool"></v-list-item>
          </v-list-group>

          <!-- 使い方 -->
          <v-list-item title="使い方" value="usage" to="/usage" :prepend-icon="mdiHelp"></v-list-item>

          <!-- ログアウト -->
          <v-list-item title="ログアウト" value="logout" @click="showLogoutDialog" :prepend-icon="mdiLogout"></v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <!-- ログアウト確認ダイアログ -->
    <v-dialog v-model="logoutDialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5">ログアウト確認</v-card-title>
        <v-card-text>本当にログアウトしますか？</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="logoutDialog = false">キャンセル</v-btn>
          <v-btn color="blue darken-1" text @click="logout">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script setup>
import axios from 'axios';
import { ref, watch } from "vue";
import { useRoute } from "vue-router";

// ダークテーマに変更
// この theme の値を light や dark もしくは 自作テーマ名に変更すればテーマを変更できる
const theme = ref("dark");
const drawer = ref(false);
const logoutDialog = ref(false);
const isLoggedIn = ref(false);
// const avatarUrl = ref('/path/to/default/avatar.jpg'); // デフォルトのアバター画像のパス
const route = useRoute();

// const pageTitle = computed(() => {
//   return route.meta.title || 'デフォルトのタイトル';
// });

watch(route, (newRoute) => {
  document.title = newRoute.meta.title || 'デフォルトのタイトル';
});

function showLogoutDialog() {
  logoutDialog.value = true;
}

function logout() {
  logoutDialog.value = false;
  // ログアウト処理を実行するAPIエンドポイントにリクエストを送信するなどの適切な処理を実装する
  // この例では/logoutにGETリクエストを送信してログアウトを行い、その後ログイン画面にリダイレクトする
  fetch("/account/logout", {
    method: "GET",
  })
    .then((response) => {
      // ログアウトが成功した場合、ログイン画面にリダイレクトする
      if (response.ok) {
        window.location.href = "/account/login"; // ログイン画面にリダイレクト
      } else {
        // ログアウトが失敗した場合、適切なエラーハンドリングを行う
        console.error("ログアウトに失敗しました");
      }
    })
    .catch((error) => {
      console.error("エラーが発生しました", error);
    });
}

function goToProfile() {
  window.location.href = '/account/profile';
}

function checkSession() {
  axios.get('/account/check_session')
    .then(response => {
      isLoggedIn.value = response.data.logged_in;
      // if (isLoggedIn.value) {
      //   avatarUrl.value = response.data.avatar_url; // サーバーから取得したアバターURLを設定
      // }
      // console.log('Session checked:', response.data);
    })
    .catch(error => {
      console.error('Error checking session:', error);
    });
}

checkSession();
</script>
