<template>
  <v-container>
    <h1 class="text-center">使い方</h1>

    <p class="text-center mt-2">
      基本的な使い方については
      <a href="https://www.canva.com/design/DAGKy9lmfso/HWsznOFN7yRteTeeCCLvMw/edit?utm_content=DAGKy9lmfso&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton">こちら</a>
      をご参照ください！！
    </p>

    

  </v-container>
</template>