import { createRouter, createWebHistory } from 'vue-router';

import LoginView from '../views/account/Login-View.vue'; // ログインビューのインポート
import RegisterView from '../views/account/Register-View.vue'; // アカウント登録ビューのインポート
import ProfileView from '../views/account/Profile-View.vue'; // プロフィールビューのインポート

import IndexView from '../views/Index-View.vue'; // インデックスビューのインポート
import NotFoundView from '../views/NotFound-View.vue'; // ページが見つからない場合のビューのインポート

import PyvisView from '../views/Pyvis-View.vue'; // Pyvisビューのインポート

import FoldersListView from '../views/folders/List-View.vue'; // フォルダリストビューのインポート

import ChunksListView from '../views/chunks/List-View.vue'; // チャンクリストビューのインポート
import ChunksListAddView from '../views/chunks/Add-View.vue'; // チャンクリスト追加ビューのインポート
import ChunksListEditView from '../views/chunks/Edit-View.vue'; // チャンクリスト編集ビューのインポート

import ChunkSentencesListView from '../views/chunk_sentences/List-View.vue'; // チャンクセンテンスリストビューのインポート
import ChunkSentencesListCreateView from '../views/chunk_sentences/Create-View.vue'; // チャンクセンテンスリスト追加ビューのインポート
import ChunkSentencesListEditView from '../views/chunk_sentences/Edit-View.vue'; // チャンクセンテンスリスト編集ビューのインポート

import SpeakMoreSelectView from '../views/speak_more/Select-View.vue'; // 発声練習選択ビューのインポート
import SpeakMorePracticeView from '../views/speak_more/Practice-View.vue'; // 発声練習ビューのインポート
import SpeakMoreResultView from '../views/speak_more/Result-View.vue';  // 追加

import StatisticsView from '../views/Statistics-View.vue'; // 統計ビューのインポート

import UsageView from '../views/Usage-View.vue'; // 使い方ビューのインポート

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { name: 'Login', path: '/account/login', component: LoginView, meta:{title: 'ログイン'}}, // ログインルート
    { name: 'Register', path: '/account/register', component: RegisterView, meta:{title: 'アカウント登録'}}, // アカウント登録ルート
    { name: 'Profile', path: '/account/profile', component: ProfileView, meta:{title: 'プロフィール'}}, // プロフィールルート

    { name: 'Index', path: '/', component: IndexView, meta:{title: 'ホーム'}}, // ホームルート
    { name: 'NotFound', path: '/:catchAll(.*)', component: NotFoundView, meta:{title: 'ページが見つかりません'}}, // ページが見つからない場合のルート

    { name: 'Pyvis', path: '/pyvis', component: PyvisView, meta:{title: 'チャンクネットワーク'}}, // Pyvisルート

    { name: 'Folders-List', path: '/folders/list', component: FoldersListView, meta:{title: 'フォルダリスト'}}, // フォルダリストルート

    { name: 'Chunks-List', path: '/chunks/list', component: ChunksListView, meta:{title: 'チャンクリスト'}}, // チャンクリストルート
    { name: 'Chunks-List-Add', path: '/chunks/list/add', component: ChunksListAddView, meta:{title: 'チャンク追加'}}, // チャンクリスト追加ルート
    { name: 'Chunks-List-Edit', path: '/chunks/list/edit', component: ChunksListEditView, meta:{title: 'チャンク編集'}}, // チャンクリスト編集ルート

    { name: 'Chunk-Sentences-List', path: '/chunk_sentences/list', component: ChunkSentencesListView, meta:{title: 'チャンク文/フレーズリスト'}}, // チャンク文/フレーズリストルート
    { name: 'Chunk-Sentences-List-Create', path: '/chunk_sentences/list/create', component: ChunkSentencesListCreateView, meta:{title: 'チャンク文/フレーズリスト追加'}}, // チャンク文/フレーズリスト追加ルート
    { name: 'Chunk-Sentences-List-Edit', path: '/chunk_sentences/list/edit', component: ChunkSentencesListEditView, meta:{title: 'チャンク文/フレーズリスト編集'}}, // チャンク文/フレーズリスト編集ルート

    { name: 'Speak-More-Select', path: '/speak_more/select', component: SpeakMoreSelectView, meta:{title: '発声練習選択'}},
    { name: 'Speak-More-Practice', path: '/speak_more/practice', component: SpeakMorePracticeView, meta:{title: '発声練習'}},
    { name: 'Speak-More-Result', path: '/speak_more/result', component: SpeakMoreResultView, meta:{title: '発声練習結果'}},
    
    { name: 'Statistics', path: '/statistics', component: StatisticsView, meta:{title: '学習統計'}}, // 統計ルート

    { name: 'Usage', path: '/usage', component: UsageView, meta:{title: '使い方'}}, // 使い方ルート
  ],
});

export default router;
